.data-grid-container .data-grid .cell > input{
    outline: none !important;
    border: 2px solid rgb(33, 133, 208);
    text-align: left;
    width: 100%;
    height: 100%;
    background: none;
    display: block;
}
.data-grid-container .data-grid .cell{
    text-align: left;
}