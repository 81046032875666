.answers {
  width: 100vw;
  height: 80vh;
}
.select {
  text-align: left;
  z-index: 3;
  margin: 1rem 3rem;
  display: grid;
  grid-gap: 1rem;
  position: sticky;
}
.text-center {
  text-align: center;
}
.sticky-table {
  white-space: nowrap;
  margin-top: 2rem;
  border: 2px solid #e5e5e5;
  position: relative;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
}
.sticky-table .sticky-table-cell {
  padding: 0.5rem 0.75rem;
  border-bottom: 2px solid #e5e5e5;
  background-color: #fff;
  border-right: 2px solid #e5e5e5;
}
.sticky-table .sticky-table-cell:last-child {
  border-right: none;
}
.sticky-table .sticky-table-corner {
  border-right: 2px solid #e5e5e5;
}
.sticky-table .sticky-header {
  margin-top: 0;
}
.sticky-table .sticky-table-cell {
  display: table-cell;
  box-sizing: border-box;
}
.sticky-table .sticky-table-row {
  display: table-row;
}
.sticky-table .sticky-table-table {
  display: table;
  box-sizing: border-box;
}
@media only screen and (max-device-width: 736px) {
  .sticky-table .sticky-header {
    padding-top: 1px;
    top: -1px;
  }
}
.resizing-cells .row:not(:first-child) .cell:not(:first-child) {
  cursor: pointer;
}
.resizing-cells .row:not(:first-child) .cell:not(:first-child):hover {
  background-color: #f5f5f5;
}
